<template>
  <div id="service-list" v-if="plan_mst">
      <!-- <v-alert v-show="request_points.length === 0" type="info" dense outlined>サービスは登録されていません</v-alert> -->

      <v-data-table dense disable-sort :headers="table_headers" :items-per-page="-1" :items="request_points" class="elevation-0" hide-default-footer hide-default-header no-data-text="サービスは登録されていません">
        <template v-slot:[`item.count`]="{ item }">
          {{ item.fix_count}}<span class="unit">{{unit}}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="editRequestPoints(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:[`body.append`]>
          <tr v-if="totalPoint > 0 && point_rate !== 1">
            <td class="text-right">小計</td>
            <td colspan="1" class="text-right px-2"><span class="font-weight-bold">{{ totalPoint }}</span><span class="unit">{{unit}}</span>
            </td>
            <td></td>
          </tr>
          <tr v-if="point_rate && point_rate !== 1">
            <td class="text-right">倍率</td>
            <td colspan="1" class="text-right px-2"><span class="font-weight-bold">{{ point_rate }}</span><span class="unit">倍</span></td>
            <td></td>
          </tr>
          <tr v-if="totalPoint > 0 && point_rate">
            <td class="text-right">合計</td>
            <td colspan="1" class="text-right px-2"><span class="text-h6">{{ totalPoint * point_rate }}<span class="unit">{{unit}}</span></span></td>
            <td></td>
          </tr>
        </template>
      </v-data-table>

      <div class="text-center mb-1" v-if="!readonly">
        <v-btn fab small color="primary" @click="addNewRequestService()"><v-icon>mdi-table-row-plus-after</v-icon></v-btn>
      </div>

    <v-dialog persistent v-model="crud.dialog" max-width="600px">
      <v-card>
        <v-card-title v-if="crud.editedIndex === -1">サービス追加</v-card-title>
        <v-card-title v-else>サービス編集</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="10">
                <v-select label="サービス" v-model="crud.form.plan_mst_service" hide-details :items="plan_mst.plan_mst_service" item-text="point_mst.service" item-value="point_mst.id" return-object ></v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field type="number" hide-details :suffix="unit" min="0" v-model="crud.form.fix_count"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="" text @click="closeDialog()">キャンセル</v-btn>
          <v-btn v-if="mode == 'new'" color="primary" @click="addRequestPoints()" :disabled="!crud.form.point_mst.id">追加</v-btn>
          <v-btn v-else color="primary" @click="updateRequestPoints()" :disabled="!crud.form.id">追加</v-btn>
          <v-spacer />
          <confirm-dialog @accept="removeRequestPoints" v-if="crud.editedIndex !== -1">
            <template v-slot:title>このサービスを削除しますか？</template>
            <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
            </template>
          </confirm-dialog>

        </v-card-actions>
      </v-card>
        <v-overlay :value="isLoading" absolute>
          <v-progress-circular indeterminate size="64" />
        </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from './ConfirmDialog.vue'

export default {
  name: 'serviceList',
  props: [
    'request_id', 'readonly', 'point_rate'
  ],
  components: { ConfirmDialog },
  data: () => ({
    mode: 'new',
    isLoading: false,
    plan_mst: null,
    request_points: [],
    table_headers: [
      { text: 'サービス名', value: 'point_mst.service' },
      { text: '消費', value: 'count', align: 'end', cellClass: 'point px-2' },
      { text: '', value: 'actions', cellClass: 'action' }
    ],
    toEditId: '',
    crud: {
      dialog: false,
      editedIndex: -1,
      form: {
        create_at: '',
        fix_count: 0,
        id: null,
        point_allocate_flag: 1,
        point_mst: {},
        point_mst_id: null,
        request_id: null,
        update_at: '',
        use_count: 1
      },
      defaultItem: {
        create_at: '',
        fix_count: 0,
        id: null,
        point_allocate_flag: 1,
        point_mst: {},
        point_mst_id: null,
        request_id: null,
        update_at: '',
        use_count: 1
      }
    }
  }),
  computed: {
    isPointModel () {
      return (this.plan_mst.contract_model === 'point')
    },
    unit: function () {
      if (this.plan_mst) {
        return (this.isPointModel) ? 'Pt' : '回'
      }
      return ''
    },
    totalPoint: function () {
      let total = 0
      for (const item of this.request_points) {
        total += parseInt(item.fix_count)
      }
      return total
    }
  },
  created: function () {
    this.readDataFromAPI()
  },
  methods: {
    readDataFromAPI: function () {
      const self = this
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${self.request_id}`)
        .then(res => {
          self.plan_mst = res.data.plan_mst
          self.request_points = res.data.request_points
        })
        .catch(err => {
          console.log(err)
        })
    },
    addRequestPoints: function () {
      const requestPoint = {
        point_mst: this.crud.form.point_mst,
        fix_count: (this.crud.form.fix_count) ? this.crud.form.fix_count : 0
      }
      this.request_points.push(requestPoint)
      this.$emit('add', requestPoint) // 親コンポーネントにイベント通知
      this.closeDialog()
    },
    removeRequestPoints: function (index) {
      this.request_points.splice(index, 1)
      this.$emit('remove', this.crud.form) // 親コンポーネントにイベント通知
      this.closeDialog()
    },
    updateRequestPoints: function () {
      this.crud.form.toEditId = this.toEditId
      this.$emit('edit', this.crud.form) // Event notification to parent component
      this.closeDialog()
    },
    editRequestPoints (item) { // edit icon click to open dialog
      this.mode = 'edit'
      this.crud.editedIndex = this.request_points.indexOf(item)
      this.crud.form = Object.assign({}, item)
      this.toEditId = this.crud.form.id
      this.crud.dialog = true
    },
    addNewRequestService () { // add icon click to open dialog
      this.mode = 'new'
      this.$nextTick(() => {
        this.crud.form = Object.assign({}, this.crud.defaultItem)
        this.crud.editedIndex = -1
      })
      this.crud.dialog = true
    },
    closeDialog () {
      this.crud.dialog = false
      this.$nextTick(() => {
        this.crud.form = Object.assign({}, this.crud.defaultItem)
        this.toEditId = ''
        this.crud.editedIndex = -1
      })
    }
  },
  watch: {
    'crud.form.plan_mst_service': function (val) {
      this.crud.form.fix_count = (val) ? val.point : 0
      this.crud.form.point_mst = (val) ? val.point_mst : {}
    },
    request_points: function (val) {
      this.$emit('change', val) // 親コンポーネントにイベント通知
    },
    dialog: function (val) { // ClearSelected
      if (!val) this.crud.form.plan_mst_service = null
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep td.action{
    width: 30px;
  }
  ::v-deep td.point{
    width: 50px;
  }
</style>
