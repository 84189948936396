<template>
  <v-form ref="form" v-model="form.valid">
    <v-dialog max-width="800px" value="true" persistent>
      <v-card>
        <v-card-title v-if="params.state_to" class="py-1">
          ステータスの変更<v-spacer />
          <v-chip-group>
            <v-chip class="mr-0">{{ params.state_from.status }}</v-chip>
            <v-icon class="mx-1">mdi-transfer-right</v-icon>
            <v-chip class="primary">{{ params.state_to.status }}</v-chip>
          </v-chip-group>
        </v-card-title>
        <v-card-title v-else >
          新規コメント
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-container>
          <template v-if="params.state_to">
            <template v-if="is_admin_user">
              <v-row>
                <v-col cols="5">
                  <v-select :items="submit_login_id_list" :rules="form.assigned_login_id_rules" label="担当者" v-model="form.assigned_login_id"></v-select>
                </v-col>
                <v-col md="2" class="">
                  <v-text-field label="作業時間" v-model="form.working_hours" type="number" min="0" max="10000" suffix="時間" class="centered-input"></v-text-field>
                </v-col>
                <v-col md="2" class="pl-0">
                  <v-text-field v-model="form.working_minutes" type="number" min="0" max="59" suffix="分" class="centered-input"></v-text-field>
                </v-col>
                <v-col cols="3" v-if="params.state_to.id === 2 && params.request.plan_mst.contract_model == 'point'">
                   <v-select :items="point_rate" label="ポイント倍率" suffix="倍" v-model="form.point_rate" class=""></v-select>
                </v-col>
              </v-row>
              <v-sheet outlined rounded class="pb-3 mb-4" v-if="params.state_to.id === 2">
                <RequestService :request_id="params.request.id" :point_rate="form.point_rate" v-on:change="changeRequestService" />
              </v-sheet>
            </template>
          </template>
          <v-textarea v-model="form.comment" :rules="form.comment_rules" class="mt-3" label="コメント*" outlined required auto-grow ></v-textarea>
          <v-row>
            <v-col>
              <v-btn @click="addImageMemo" class="mr-3"><v-icon right class="mr-1">mdi-file-image-plus</v-icon>画面メモの追加</v-btn>
              <a href="https://aun.app/qqnpm" target="_blank" rel="noopener" class="text-decoration-none d-inline-block" style="font-size: 12px; color: rgba(0, 0, 0, 0.87);"><v-icon style="font-size: 14px;">mdi-help-circle</v-icon>使い方をみる</a>
            </v-col>
            <v-col>
              <v-file-input :rules="form.files_rules" v-model="form.files" class="my-0 py-0" small-chips multiple counter show-size label="ファイル添付" hint="複数選択可" persistent-hint ></v-file-input>
            </v-col>
          </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 py-0" v-show="show_form_request_assigned_login_id">
          <v-container class="pt-2 pb-0">
            <v-row>
              <v-checkbox v-model="form.request_assigned_login_id_enabled" label="タスクを渡す" class="mr-4">
                <template v-slot:label>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">タスクを渡す</span>
                    </template>
                    このコメントを確認してほしい方に<br>タスクを割り当てます。
                  </v-tooltip>
                </template>
              </v-checkbox>
              <v-select :items="form.request_assigned_login_id_list" :rules="form.request_assigned_login_id_rules" v-model="form.request_assigned_login_id" :disabled="!form.request_assigned_login_id_enabled" class="request_assigned_login_id_list">
                <template v-slot:selection="{ item }">
                  <LoginAvatar :user="item.user" :size="32" class="mr-1" />
                  {{ item.text }}
                </template>
                <template v-slot:item="{ item }">
                  <LoginAvatar :user="item.user" :size="32" class="mr-1" />
                  {{ item.text }}
                </template>
              </v-select>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider v-show="show_form_request_assigned_login_id"></v-divider>
        <v-card-actions>
          <v-switch v-if="is_admin_user" v-model="form.send_mail_flag" label="お知らせメール" class="mt-0" hide-details=""></v-switch>
          <v-spacer />
          <v-btn @click="close" text>キャンセル</v-btn>
          <v-btn v-promise-btn color="primary" @click="send" min-width="15%">
          <template><v-icon left>mdi-comment-plus</v-icon>送信</template>
          </v-btn>
          <v-spacer />
          <v-spacer v-if="is_admin_user" />
        </v-card-actions>
      </v-card>
        <v-overlay :value="isLoading" absolute>
          <v-progress-circular indeterminate size="64" />
        </v-overlay>
    </v-dialog>
  </v-form>
</template>

<script>
import RequestService from '@/components/RequestService.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'
import axios from '@/plugins/axios'
import { mapState } from 'vuex'

export default {
  name: 'statusChange',
  props: { params: Object },
  components: {
    RequestService,
    LoginAvatar
  },
  data: () => ({
    isLoading: false,
    form: {
      valid: true,
      assigned_login_id: null,
      assigned_login_id_rules: [
        v => !!v || '担当者は必ず選択してください'
      ],
      working_hours: 0,
      working_minutes: 0,
      point_rate: 1,
      request_points: [],
      comment: sessionStorage.getItem('comment') ? sessionStorage.getItem('comment') : '',
      comment_rules: [
        v => !!v || 'コメントは必ず入力してください'
      ],
      files: null,
      files_rules: [
        files => !files || !files.some(file => file.size > (20 * 1000 * 1000)) || 'サイズが大きすぎます'
      ],
      request_assigned_login_id_enabled: true,
      request_assigned_login_id_list: [],
      request_assigned_login_id: null,
      request_assigned_login_id_rules: [],
      send_mail_flag: true
    }
  }),
  mounted () {
    if (this.params.request.point_rate) {
      this.form.point_rate = this.params.request.point_rate
    }
    if (this.submit_login_id_list.some(element => element.value === this.login_user_id)) {
      this.form.assigned_login_id = this.login_user_id
    }
    // 「タスクを渡す」の初期値
    if ([1, 2].indexOf(this.params?.state_to?.flag) >= 0) {
      this.form.request_assigned_login_id_enabled = false // 「受付完了、作業中」にステータス変更するときは「タスクを渡す」を無効にする
    }
    // 「タスク所有者」の選択オプション
    this.form.request_assigned_login_id_list = this.getRequestAssignedLoginIdList()
    // 「タスク所有者」の初期値
    this.form.request_assigned_login_id = this.getRequestAssignedLoginIdDefault()
  },
  computed: {
    ...mapState(['point_rate']),
    login_user_id: function () {
      return this.$store.state.login_user.id
    },
    is_admin_user: function () {
      return this.$store.getters.loginUserInfo.is_admin_user
    },
    submit_login_id_list: function () {
      const list = []
      if (this.params.request.involved_logins) {
        if ([0, 4, 6].indexOf(this.params?.state_to?.flag) >= 0) {
          this.params.request.involved_logins.end_user.forEach(item => {
            list.push({
              text: item.name,
              value: item.id
            })
          })
        } else {
          this.params.request.involved_logins.admin_company.forEach(item => {
            list.push({
              text: item.name,
              value: item.id
            })
          })
        }
      }
      return list
    },
    show_form_request_assigned_login_id: function () {
      // クライアントかつ「検収」のときは「タスクを渡す」を表示しない
      if (this.is_admin_user !== true && [6].indexOf(this.params?.state_to?.flag) >= 0) {
        return false
      }
      return true
    }
  },
  methods: {
    changeRequestService: function (val) {
      this.form.request_points = val
    },
    send: function () {
      const _this = this
      if (_this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('comment', _this.form.comment)
        if (_this.form.request_assigned_login_id_enabled) {
          formData.append('assigned_login_id', _this.form.request_assigned_login_id)
        }
        formData.append('send_mail_flag', _this.form.send_mail_flag ? '1' : '0')
        if (_this.form.files) {
          for (const file of _this.form.files) {
            formData.append('files[]', file, file.name)
          }
        }
        if (_this.params.state_to) {
          formData.append('status_history[assigned_login_id]', _this.form.assigned_login_id)
          formData.append('status_history[status_mst_id]', _this.params.state_to.id)
          formData.append('status_history[working_hours]', _this.form.working_hours)
          formData.append('status_history[working_minutes]', _this.form.working_minutes)
          if (_this.form.request_points) {
            this.form.request_points.forEach((value, index) => {
              formData.append(`request_points[${index}][point_mst_id]`, value.point_mst.id)
              formData.append(`request_points[${index}][fix_count]`, value.fix_count)
            })
          }
          formData.append('point_rate', _this.form.point_rate)
        }
        _this.isLoading = true
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${_this.params.request.id}/request_comment`, formData)
            .then(res => {
              _this.$emit('reload')
              _this.$store.commit('setFlashSuccess', 'コメントを投稿しました。')
              _this.isLoading = false
              resolve(res)
              _this.close()
              sessionStorage.removeItem('comment')
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              _this.isLoading = false
              reject(err)
              _this.close()
            })
        })
      }
    },
    close: function () {
      sessionStorage.setItem('comment', this.form.comment)
      this.$emit('close')
    },
    addImageMemo: function () {
      const _self = this
      let _vWin = null
      window.addEventListener('message', function addMessageFunc (event) {
        const _message = event.data
        if (typeof _message === 'string' || _message instanceof String) {
          if (_self.form.comment.length !== '') {
            _self.form.comment += '\n'
          }
          _self.form.comment += event.data
          _vWin.close()
          this.removeEventListener('message', addMessageFunc)
        }
      })
      _vWin = window.open(_self.$store.getters.getVisualRelayUrl, '_vWin')
      _vWin.focus()
    },
    getRequestAssignedLoginIdList: function () {
      const list = []
      list.push({
        text: 'なし',
        value: '',
        user: {}
      })
      if (this.params.request.involved_logins) {
        this.params.request.involved_logins.end_user.forEach(item => {
          list.push({
            text: item.name,
            value: item.id,
            user: item
          })
        })
        this.params.request.involved_logins.admin_company.forEach(item => {
          list.push({
            text: item.name,
            value: item.id,
            user: item
          })
        })
      }
      return list
    },
    getRequestAssignedLoginIdDefault: function () {
      let id = ''
      if (this.is_admin_user) {
        const comment = this.params.request.timeline.slice().reverse().find(item => item.comment && !item.submit_login.is_admin_user)
        if (comment) {
          id = comment.submit_login_id
        } else {
          id = this.params.request.submit_login_id
        }
      } else {
        const comment = this.params.request.timeline.slice().reverse().find(item => item.comment && item.submit_login.is_admin_user)
        if (comment) {
          id = comment.submit_login_id
        } else {
          id = this.params.request.end_user.primary_contact_login_id
        }
      }
      // 「タスクを渡す」が非表示のときはタスク所有者を「なし」にする
      if (this.show_form_request_assigned_login_id !== true) {
        id = ''
      }
      return id
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.request_assigned_login_id_list) {
  &.v-input--is-disabled {
    .v-avatar {
      opacity: 0.38;
    }
  }
  .v-input__slot {
    &:before,
    &:after {
      border: none !important;
    }
    .v-select__slot {
      .v-input__append-inner {
        display: none !important;
      }
    }
  }
}
</style>
